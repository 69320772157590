@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
    text-align: center;
}

body {
    font-family: 'Karla', sans-serif;
}

.text-input {
    @apply w-full border border-[#EDEDED] rounded-lg py-3 px-[14px] mt-[6px];
}

.gradient-border {
    position: relative;
    padding: 12px 24px;
    display: inline-block;
}

.gradient-border::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 10px;
    padding: 2px;
    background: linear-gradient(90deg, #AE8CE7, #7239D6);
    -webkit-mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
}

.gradient-text {
    font-weight: bolder;
    background: linear-gradient(90deg, #AE8CE7, #7239D6);
    text-decoration: underline;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    position: relative;
}


.gradient-text::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -2px;
    height: 2px;
    width: 100%;
    background: linear-gradient(90deg, #AE8CE7, #7239D6);
    z-index: -1;
}

.slick-slide {
    padding: 0 10px;
}

.slick-list {
    padding: 0 -10px !important;
}

.slick-dots li {
    display: flex !important;
    justify-content: center !important;
    margin: 0 !important;
}

.custom-dots {
    display: flex !important;
    align-items: center !important;
    bottom: -35px !important;
}

.custom-dot {
    width: 15px;
    height: 15px;
    min-width: 15px;
    background-color: #AE8CE7;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px !important;
    cursor: pointer;
    opacity: 50%;
}

.custom-dot span {
    display: none;
}

.custom-dots li.slick-active {
    width: max-content !important;
}

.custom-dots li.slick-active .custom-dot {
    height: 15px;
    width: 25px;
    background: linear-gradient(90deg, #AE8CE7 0%, #7239D6 82.16%);
    border-radius: 30px;
    opacity: 100%;
}

.slick-prev::before {
    display: none;
}

.slick-next::before {
    display: none;
}

.custom-arrow {
    font-size: 24px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    width: 30px !important;
    height: 30px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50%;
    cursor: pointer;
    position: absolute !important;
    top: 85% !important;
    transform: translateY(-50%) !important;
    z-index: 1;
}

.prev-arrow {
    left: 4% !important;
}

.next-arrow {
    left: 8% !important;
}


@media only screen and (max-width: 1024px) {
    .prev-arrow {
        left: 5% !important;
    }

    .next-arrow {
        left: 12% !important;
    }
}

@media only screen and (max-width: 768px) {
    .custom-arrow {
        top: 90% !important;
    }

    .prev-arrow {
        left: 45% !important;
    }

    .next-arrow {
        left: 55% !important;
    }
}

@media only screen and (max-width: 420px) {
    .custom-arrow {
        top: 92% !important;
    }

    .prev-arrow {
        left: 35% !important;
    }

    .next-arrow {
        left: 50% !important;
    }
}